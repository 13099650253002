<template>
  <div>
    <div id="form" class="bg-light">
      <div id="ctaSection" class="container space-2">
        <div class="text-center w-md-60 mx-auto">
          <h2 class="mb-3">{{ $t('EnterprisePage.FormSection.Title') }}</h2>
          <p class="mb-4">{{ $t('EnterprisePage.FormSection.Description') }}</p>
          <div class="mx-auto mt-5">
            <div class="card z-index-2">
              <div class="card-body">
                <div id="hubspotRegisterForm" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SVG Background Shape -->
      <!-- <figure class="w-100 position-absolute bottom-0 left-0">
        <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
      </figure> -->
    <!-- End SVG Background Shape -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Form',
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7091000',
          formId: 'b0759c2b-b8f9-4174-bebe-334b98efcf62',
          errorClass: 'hubspotFormError',
          target: '#hubspotRegisterForm'
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
// :style="{ background: `url(${require(`@/assets/svg/components/isometric-lego.svg`)}) center no-repeat`}"
</style>
