<template>
  <div class="position-relative">
    <div class="container space-bottom-2">
      <div class="rounded overflow-hidden space-top-2 space-top-lg-0 pl-3 pl-md-6 shadow" style="background-color:#EAF4FD">
        <div class="row justify-content-lg-between align-items-lg-center gutters">
          <div class="col-lg-5">
            <div class="mb-4 pr-4">
              <h2 class="mb-3">{{ $t('EnterprisePage.CTASection.Title') }}</h2>
              <p class="mb-4">{{ $t('EnterprisePage.CTASection.Description') }}</p>
            </div>
            <a href="javascript:;" data-toggle="modal" data-target="#video2Modal" class="btn btn-primary" target="_blank"><i class="fas fa-play-circle mr-1" /> {{ $t('APIProviderPage.VideoSection.Watch') }}</a>
          </div>
          <!-- Video -->
          <div class="col-lg-7 space-1">
            <div data-gtm-att="ga-intro-video-card" class="card shadow overflow-hidden bg-light mr-4">
              <a href="javascript:;" data-toggle="modal" data-target="#video2Modal" class="video-player">
                <img class="img-fluid video-player-preview" :src="require('@/assets/img/academy/' + `${ $t('AcademyPage.VideoSection.OahEnterprise.ImageArrow') }` + '.jpg')">
                <!-- <div class="video-player-btn video-player-centered">
                  <span class="video-player-icon">
                    <i class="fas fa-play font-size-2" />
                  </span></div> -->
                <div class="embed-responsive embed-responsive-16by9">
                  <div id="oahVideoIframe" />
                </div>
              </a>
            </div>
            <div id="video2Modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="video2ModalTitle" aria-hidden="true">
              <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 id="video2ModalTitle" class="modal-title" />
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                      <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                      </svg>
                    </button>
                  </div>
                  <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                    <div style="padding:52% 0 0 0;position:relative;">
                      <iframe :src="$t('AcademyPage.VideoSection.OahEnterprise.Video')" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="$t('AcademyPage.VideoSection.OahEnterprise.Title')" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')

export default {
  name: 'Video',
  mounted() {
    $('#video2Modal').on('hidden.bs.modal', function(e) {
      $('#video2Modal iframe').attr('src', $('#video2Modal iframe').attr('src'))
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>

<!-- <template>
  <div class="container space-bottom-2">
    <div class="rounded overflow-hidden space-top-2 space-top-lg-0 pl-5 pl-md-8 shadow" style="background-color:#1c344c">
      <div class="row justify-content-lg-between align-items-lg-center no-gutters">
        <div class="col-lg-6">
          <h2 class="mb-3 text-white">{{ $t('EnterprisePage.CTASection.Title') }}</h2>
          <p class="mb-4 text-white">{{ $t('EnterprisePage.CTASection.Description') }}</p>
          <a class="btn btn-primary text-white" @click="scrollToForm">
            {{ $t('EnterprisePage.HeroSection.Button') }}
          </a>        </div>
        <div class="col-lg-6 space-top-1 ml-auto">
          <div class="w-85 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-community.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA',
  methods: {
    scrollToForm() {
      var elmnt = document.getElementById('form')
      elmnt.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style> -->
