<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="row justify-content-lg-between align-items-lg-center">
        <div class="col-lg-6 mb-2 mb-md-0">
          <div class="mb-3">
            <h2>{{ $t('EnterprisePage.OverviewSection.Title') }}</h2>
          </div>
          <p>{{ $t('EnterprisePage.OverviewSection.Description') }}</p>
        </div>
        <div class="col-lg-6">
          <div class="w-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/enterprise-api-hub-overview.svg">
          </div>
        </div>
      </div>
      <div class="w-75 mx-auto my-7">
        <img class="img-fluid" src="@/assets/svg/components/three-pointers.svg">
      </div>

      <div class="w-md-85 w-lg-65 text-center mx-auto mb-7">
        <h3>{{ $t('EnterprisePage.OverviewSection.4PillarsTitle') }}</h3>
      </div>

      <div class="row">
        <div class="col-lg-6 mb-4">
          <div class="rounded overflow-hidden space-top-2 space-top-lg-1 px-5 px-md-8 mb-7" style="background-color:#f3f9fe">
            <div class="mb-4">
              <h2>{{ $t('EnterprisePage.OverviewSection.APIHubTitle') }}</h2>
              <p>{{ $t('EnterprisePage.OverviewSection.APIHubDescription') }}</p>
            </div>
            <img class="img-fluid shadow" src="@/assets/img/screenshots/enterprise-api-hub.jpg">
          </div>
        </div>

        <div class="col-lg-6 mb-4">
          <div class="rounded overflow-hidden space-top-2 space-top-lg-1 px-5 px-md-8 mb-7" style="background-color:#e3f1fd">
            <div class="mb-4">
              <h2>{{ $t('EnterprisePage.OverviewSection.DeveloperAdminPortalTitle') }}</h2>
              <p>{{ $t('EnterprisePage.OverviewSection.DeveloperAdminPortalDescription') }}</p>
            </div>
            <img class="img-fluid" src="@/assets/img/screenshots/enterprise-dp.png">
          </div>
        </div>

        <div class="col-lg-6 mb-4">
          <div class="rounded overflow-hidden space-top-2 space-top-lg-1 px-5 px-md-8 mb-7" style="background-color:#d4e9fb">
            <div class="mb-4">
              <h2>{{ $t('EnterprisePage.OverviewSection.ProviderAdminPortalTitle') }}</h2>
              <p>{{ $t('EnterprisePage.OverviewSection.ProviderAdminPortalDescription') }}</p>
            </div>
            <img class="img-fluid" src="@/assets/img/screenshots/enterprise-pp.png">
          </div>
        </div>

        <div class="col-lg-6 mb-4">
          <div class="rounded overflow-hidden space-top-2 space-top-lg-1 px-5 px-md-8 mb-7" style="background-color:#c4e1fa">
            <div class="mb-4">
              <h2>{{ $t('EnterprisePage.OverviewSection.OperatorAdminPortalTitle') }}</h2>
              <p>{{ $t('EnterprisePage.OverviewSection.OperatorAdminPortalDescription') }}</p>
            </div>
            <img class="img-fluid" src="@/assets/img/screenshots/enterprise-op.png">
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Overview'
}
</script>

<style lang="scss" scoped>
</style>
