<template>
  <div id="more-features">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-auto mb-7">
        <h2>{{ $t('EnterprisePage.CaseStudySection.Title') }}</h2>
        <p>{{ $t('EnterprisePage.CaseStudySection.Description') }}</p>
      </div>

      <!-- <div class="position-relative py-5">
        <div class="position-relative z-index-2 p-2 p-sm-0">
          <div class="w-lg-50 mx-auto">
            <div class="card flex-wrap flex-row h-100 shadow-lg">
              <div class="card-body pt-5 pb-0 px-6">
                <h3>{{ $t('EnterprisePage.CaseStudySection.Case1.Title') }}</h3>
                <p>{{ $t('EnterprisePage.CaseStudySection.Case1.Description') }}</p>
                <a href="https://www.hkstp.org/press-room/hkstp-and-hsbc-join-hands-to-drive-open-banking-innovation-in-hong-kong/" target="_blank" class="font-weight-bold">{{ $t('EnterprisePage.CaseStudySection.Case1.Button') }} <i class="fas fa-chevron-right ml-1" /></a>
                <figure class="w-85 mx-auto mt-5">
                  <img class="img-fluid shadow" src="@/assets/img/screenshots/ecobooster-case-study.jpg">
                </figure>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-navy position-absolute top-0 right-0 bottom-0 left-0 rounded-lg mx-sm-7" style="background-image: url(@/assets/svg/components/abstract-shapes-20.svg);" />
      </div> -->

      <!-- eslint-disable -->
      <div class="position-relative py-5">
        <div class="position-relative z-index-2 p-2 p-sm-0">
         <Slick ref="showcasesCarousel" :options="slickOptions" class="slick-equal-height slick-gutters-3">

          <div class="card border flex-wrap flex-row h-100 shadow-sm">
            <div class="card-body p-xl-6">
              <h3 class="text-center">{{ $t('EnterprisePage.CaseStudySection.Case1.Title') }}</h3>
              <img class="img-fluid shadow my-3" src="@/assets/img/screenshots/ecobooster-case-study.jpg">
              <p>{{ $t('EnterprisePage.CaseStudySection.Case1.Description') }}</p>
              <a :href="$t('EnterprisePage.CaseStudySection.Case1.URL')" target="_blank" class="font-weight-bold">{{ $t('EnterprisePage.CaseStudySection.Case1.Button') }} <i class="fas fa-chevron-right ml-1" /></a>
            </div>
          </div>

          <div class="card border flex-wrap flex-row h-100 shadow-sm">
            <div class="card-body p-xl-6">
              <h3 class="text-center">{{ $t('EnterprisePage.CaseStudySection.Case2.Title') }}</h3>
              <img class="img-fluid shadow my-3" src="@/assets/img/screenshots/openapi-platform-case-study.jpg">
              <p>{{ $t('EnterprisePage.CaseStudySection.Case2.Description') }}</p>
              <a :href="$t('EnterprisePage.CaseStudySection.Case2.URL')" target="_blank" class="font-weight-bold">{{ $t('EnterprisePage.CaseStudySection.Case1.Button') }} <i class="fas fa-chevron-right ml-1" /></a>
            </div>
          </div>

            
          </Slick>
       
        </div>

        <!-- Background -->
        <div class="bg-navy position-absolute top-0 right-0 bottom-0 left-0 rounded-lg mx-sm-7" style="background-image: url(@/assets/svg/components/abstract-shapes-20.svg);" />
      </div>
      
      
          
			<!-- eslint-enable -->

    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'

export default {
  name: 'CaseStudy',
  components: {
    Slick
  },
  data() {
    return {
      slickOptions: {
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        prevArrow: '<span class="fa fa-arrow-left slick-arrow slick-arrow-primary slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-n1" />',
        nextArrow: '<span class="fa fa-arrow-right slick-arrow slick-arrow-primary slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-n1" />',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  beforeUpdate() {
    if (this.$refs.showcasesCarousel) {
      this.$refs.showcasesCarousel.destroy()
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.showcasesCarousel) {
        this.$refs.showcasesCarousel.create(this.slickOptions)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~slick-carousel/slick/slick.css';
</style>
