<template>
  <div id="">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-auto mb-7">
        <h2>{{ $t('EnterprisePage.TrustSection.Title') }}</h2>
        <p>{{ $t('EnterprisePage.TrustSection.Description') }}</p>
      </div>

      <div class="row mx-n2">
        <div class="col-md-6 px-2 mb-3 mb-lg-0">
          <div class="card h-100" href="#">
            <div class="card-body">
              <div class="media">
                <figure class="w-100 max-w-6rem mr-3">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-47.svg">
                </figure>
                <div class="media-body">
                  <h3>{{ $t('EnterprisePage.TrustSection.Card1Title') }}</h3>
                  <p class="text-body">{{ $t('EnterprisePage.TrustSection.Card1Description') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 px-2 mb-3 mb-lg-0">
          <div class="card h-100" href="#">
            <div class="card-body">
              <div class="media">
                <figure class="w-100 max-w-6rem mr-3">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-47.svg">
                </figure>
                <div class="media-body">
                  <h3>{{ $t('EnterprisePage.TrustSection.Card2Title') }}</h3>
                  <p class="text-body">{{ $t('EnterprisePage.TrustSection.Card2Description') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Trust'
}
</script>

<style lang="scss" scoped>
</style>
