<template>
  <div class="position-relative overflow-hidden">
    <div style="background-color:#16283A;">
      <div class="container space-top-2 space-bottom-1">
        <div class="w-md-85 w-lg-65 mx-auto text-center">
          <span class="d-block small font-weight-bold text-cap mb-2 text-white">{{ $t('EnterprisePage.HeroSection.Subtitle') }}</span>
          <h1 class="text-white">{{ $t('EnterprisePage.HeroSection.Title') }}</h1>
          <p class="lead mb-4 text-white">{{ $t('EnterprisePage.HeroSection.Description') }}</p>
          <a class="btn btn-primary text-white" @click="scrollToForm">
            {{ $t('EnterprisePage.HeroSection.Button') }}
          </a>
        </div>
        <div class="w-lg-65 mx-auto mt-4">
          <img class="img-fluid" src="@/assets/svg/illustrations/enterprise-hero.svg">
        </div>
      </div>
    </div>
    <!-- <div class="">
      <img class="img-fluid" src="@/assets/svg/components/tech-startup-bg.svg">
    </div> -->

  </div>

</template>

<script>
export default {
  name: 'Hero',
  methods: {
    scrollToForm() {
      var elmnt = document.getElementById('form')
      elmnt.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
