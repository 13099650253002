<template>
  <div id="more-features" class="bg-light">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-auto mb-7">
        <h2>{{ $t('EnterprisePage.UseCaseSection.Title') }}</h2>
        <p>{{ $t('EnterprisePage.UseCaseSection.Description') }}</p>
      </div>

      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-1.svg">
          </figure>
          <h3 class="mb-3">{{ $t('EnterprisePage.UseCaseSection.UseCase1Title') }}</h3>
          <p>{{ $t('EnterprisePage.UseCaseSection.UseCase1Description') }}</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-85 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/internal-api-hub.svg">
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-2.svg">
          </figure>
          <h3 class="mb-3">{{ $t('EnterprisePage.UseCaseSection.UseCase2Title') }}</h3>
          <p>{{ $t('EnterprisePage.UseCaseSection.UseCase2Description') }}</p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-85 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/partner-api-hub.svg">
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-3.svg">
          </figure>
          <h3 class="mb-3">{{ $t('EnterprisePage.UseCaseSection.UseCase3Title') }}</h3>
          <p>{{ $t('EnterprisePage.UseCaseSection.UseCase3Description') }}</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-85 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-marketplace.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UseCases'
}
</script>

<style lang="scss" scoped>
</style>
