<template>
  <div>
    <Hero />
    <Overview />
    <CTA />
    <UseCases />
    <CaseStudy />
    <Trust />
    <Form />
  </div>
</template>

<script>
import Hero from './components/hero'
import Overview from './components/overview'
import CTA from './components/cta'
import UseCases from './components/use-case'
import Trust from './components/trust'
import CaseStudy from './components/case-study'
import Form from './components/form'

export default {
  name: 'EnterpriseAPIHub',
  components: {
    Hero,
    Overview,
    CTA,
    UseCases,
    Trust,
    CaseStudy,
    Form
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'Enterprise API Hub | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/enterprise-api-hub' }
      ],
      meta: [
        { name: 'description', content: 'Build Enterprise API Hub now with our white label solution.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'Enterprise API Hub | OpenAPIHub' },
        { property: 'og:description', content: 'Build Enterprise API Hub now with our white label solution.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/enterprise-api-hub' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
